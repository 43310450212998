.answer__details{
    display: flex;
    flex-direction: column;
    row-gap:15px;
    font-size: 16px;
    font-family: 'Gilroy-500',serif;
}

.answer__details-child{
    display: flex;
    flex-direction: column;
    row-gap:15px;
}
.answer__dots p{
    padding-left:5%;
}
.answer__dots p::before {
    content: "\2022";
    color: #5AAA65;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
.answer__dots p:first-child {
    padding:0;
}
.answer__dots p:first-child::before {
    content: none;
}
.answer__bank{
    margin-bottom:15px;
}