* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.consultation__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    row-gap: 30px;
}

.consultation__img{
    max-width: 100%;
    border-radius: 50%;
}

.consultation .col-4{
    width: 33.3333%;
    padding: 0 15px;
}

.consultation .col-8{
    width: 66.6666%;
    padding: 0 15px;

}

.consultation__box h2{
    font-weight: 700;
    font-size: 38px;
}


.consultation__program{
    width: 70%;
    font-weight: 500;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 45px;
}

.consultation__data{
    display: flex;
    justify-content: space-between;
    row-gap: 20px;
    align-items: flex-end;
    flex-wrap: wrap;
}

.consultation__enter{
    width: 30%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.consultation__label{
    font-weight: 700;
    font-size: 16px;
}
.consultation__error{
    font-weight: 700;
    font-size: 16px;
    color:red;
}

.consultation__input{
    width: 100%;
    height: 60px;
    border-radius: 5px;
    outline: none;
    border: solid 2px #B1B1B1;
    text-align: center;
}

.consultation__errNetwork{
    text-align: center;
    color:red;
    font-weight: 700;
}

/*1024 спуститься таблет*/
/*576 мобилка*/

.consultation__input::placeholder{
    color: #B1B1B1;
}

.consultation__button{
    height: 61px;
    border-radius: 5px;
    text-align: center;
}
.consultation__disabled{
    opacity: 0.5;
}

#submit:hover { cursor: pointer; }



/*media*/


@media screen and (max-width:1024px) {
    .consultation__box h2{
        text-align: left;
    }
    .consultation .col-4{
        width: 50%;
    }
    .consultation .col-8{
        width: 50%;
    }
    .consultation__program {
        width: 100%;
        font-size: 23px;
    }
    .consultation__enter {
        width: 100%;

    }
}

@media screen and (max-width:624px) {

    .consultation__box h2 {
        font-size: 34px;
    }
    .consultation__program {
        font-size: 17px;
    }
    .consultation__button {
        font-size: 16px;
    }
}


@media screen and (max-width:576px) {
    .consultation__box h2 {
        text-align: center;
    }
    .consultation__data {
        text-align: left;
        width: 85%;
        margin: 0 auto;
        row-gap:30px;
    }
    .consultation__input {
        height: 55px;
        font-size: 16px;
    }
    .consultation__button {
        height: 56px;
        font-size: 16px;
    }
    .consultation__enter, .consultation .col-4,.consultation .col-8{
        width: 100%;
    }

    .consultation__img{
        width: 80%;
    }
    .consultation__box{
        text-align: center;
    }
    .consultation__program{
        font-size: 17px;
    }
    .consultation__box h2{
        font-weight: 700;
        font-size: 30px;
    }
    .consultation__label {
        font-size: 18px;
    }
}

