#error{
    padding:200px 0;
    text-align: center;
}

.error__text{
    font-family: 'Gilroy-Bold-700',serif;
    font-size: 180px;
}
.error__box{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.error__hint{
    font-size: 48px;
    font-family: 'Gilroy-500',serif;
}
.error__box button{
    padding: 15px 30px;
    margin-top:30px;
}

@media screen and (max-width: 996px) {
    #error .col-6{
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    #error img{
        width: 60%;
    }
    .error__text{
        font-size: 100px;
    }
    .error__hint{
        font-size: 36px;
    }

}