#cover {
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 66.8%, rgba(255, 255, 255, 0.717847) 95%, #FFFFFF 99.98%), url('../../assets/images/cover.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 35%;
    padding: 0;
}

.cover__container {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.cover__wrap {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    align-items: flex-start;
}

h1 {
    font-family: 'Gilroy-Bold-800', serif;
    font-size: 64px;
    line-height: 78px;
}

.cover__wrap p {
    font-family: 'Gilroy-600', serif;
    font-size: 24px;
    line-height: 28px;
}

.cover__wrap button {
    font-family: 'Gilroy-Bold-800', serif;
    padding: 21px 71px;
    box-shadow: 0 2px 5px rgba(69, 69, 69, 0.2);
}


@media screen and (max-width: 1300px) {
    #cover {
        background-position: 40%;
    }
}

@media screen and (max-width: 1024px) {
    #cover {
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 66.8%, rgba(255, 255, 255, 0.717847) 150%, #FFFFFF 99.98%), url('../../assets/images/tablet-bg.png');
        background-position: 80%;
        background-size: cover;
    }

    .cover__wrap {
        margin-top: 0;
    }
}

@media screen and (max-width: 820px) {
    #cover {
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 66.8%, rgba(255, 255, 255, 0.717847) 150%, #FFFFFF 99.98%), url('../../assets/images/tablet-bg.png');
        background-position: 50%;
        background-size: cover;
    }

    .cover__wrap {
        margin-top: 0;
    }
}


@media screen and (max-width: 576px) {
    #cover {
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 75.82%, rgba(255, 255, 255, 0.717847) 150%, #FFFFFF 100%), url('../../assets/images/mobile-bg.png');
        background-position: 0;
    }

    .cover__container {
        justify-content: center;
    }

    .cover__wrap {
        text-align: center;
        align-items: center;
        margin-top: -100px;
        row-gap: 40px
    }

    h1 {
        font-size: 40px;
        line-height: 49px;
    }

    .cover__wrap p {
        font-size: 20px;
        line-height: 24px;
    }

    .cover__wrap button {
        width: 100%;
        padding: 21px 30px;
    }

}

@media screen and (max-width: 375px) {

    .cover__wrap button {
        font-size: 20px;
    }

    h1 {
        font-size: 35px;
    }

    .cover__wrap p {
        font-size: 18px;
    }
}

@media screen and (max-width: 320px) {
    h1 {
        font-size: 30px;
        line-height: 40px;
    }

    .cover__wrap p {
        font-size: 15px;
        line-height: 18px;
    }

    .cover__wrap button {
        font-size: 18px;
        padding: 15px 30px;
    }
}

@media screen and (max-width: 280px) {
    h1 {
        font-size: 26px;
    }

    .cover__wrap p {
        font-size: 15px;
        line-height: 18px;
    }

    .cover__wrap button {
        font-size: 16px;
    }
}