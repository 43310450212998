@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #5AAA65;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


@font-face {
    font-family: 'Gilroy-Bold-800';
    src: url('./assets/fonts/Gilroy-Extrabold.eot');
    src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
    url('./assets/fonts/Gilroy-Extrabold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gilroy-Extrabold.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Extrabold.woff') format('woff'),
    url('./assets/fonts/Gilroy-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy-Bold-700';
    src: url('./assets/fonts/Gilroy-Bold.eot');
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
    url('./assets/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Bold.woff') format('woff'),
    url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy-600';
    src: url('./assets/fonts/Gilroy-Semibold.eot');
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
    url('./assets/fonts/Gilroy-Semibold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gilroy-Semibold.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Semibold.woff') format('woff'),
    url('./assets/fonts/Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy-500';
    src: url('./assets/fonts/Gilroy-Medium.eot');
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
    url('./assets/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Medium.woff') format('woff'),
    url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}


body {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    color: #06251B;
}

a {
    text-decoration: none;
    color: black;
}

ul {
    list-style: none;
}

h2 {
    font-family: 'Gilroy-Bold-700', serif;
    font-size: 48px;
    margin-bottom: 40px;
}

button {
    border: none;
    font-family: 'Gilroy-600', serif;
    font-size: 24px;
    cursor: pointer;
    color: #06251B;
    background: #FEE242;
    border-radius: 5px;
}

section {
    padding: 100px 0;
}

.container {
    max-width: 85%;
    margin: 0 auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    margin: 0 -15px;
}

.col-6 {
    width: 50%;
    padding: 0 15px;
}

.col-3 {
    width: 25%;
    padding: 0 15px;
}




@media screen and (max-device-width: 1024px) and (orientation: landscape) {
    header {
        position: unset;
    }

    #cover button {
        margin-bottom: 30px
    }

    .footer__container {
        flex-wrap: wrap;
    }
    .show-nav {
        height: 100vh;
        overflow-y:scroll;
    }
}


@media screen and (min-width: 2000px) {
    .container {
        max-width: 1800px;
    }
}

@media screen and (max-width: 1024px) {
    h2 {
        text-align: center;
    }
}

@media screen and (max-width: 576px) {
    h2 {
        font-size: 30px;
    }
}