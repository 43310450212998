
.footer {
    padding-bottom: 97px;
    padding-top: 50px;
    background-image: url('../../assets/images/bgFooter.png');
}

.footer__p {
    color: white;
    font-family: 'Gilroy-600',serif;
}

.footer__container {
    display: flex;
    justify-content: space-between;
    row-gap: 40px;
}

.footer__title {
    font-family: 'Gilroy-Bold-700',serif;
    font-size: 24px;
    color: #5AAA65;
}

#logoFooter{
    width: 150px;
    height: 150px;
}
#qrFooter{
    width: 100px;
    height: 100px;
}

.footer__left {
    width: 65%;
}

.footer__left-wrap {
    display: flex;
    align-items: center;
    column-gap: 30px;
    height: 50%;
}

.footer__info {
    display: flex;
    row-gap: 30px;
    flex-direction: column;
}

.footer__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 10px;
}

.footer__address {
    text-align: right;
    margin-top: 5%;
}

.footer__social {
    margin-top: 15px;
    display: flex;
    column-gap: 15px;
}


.footer__switch {
    display: flex;
    column-gap: 30px;
    align-items: center;
}
.footer__company{
    font-family: 'Gilroy-500', serif;
    color: #808080;
    letter-spacing: 0.02em;
    font-size: 20px;
    margin:20px 0 0 0;
}


@media screen and (max-width: 990px) {
    .footer__title {
        font-size: 22px;;
    }

    #lineFooter {
        height: 80%;
    }

    .footer__p {
        font-size: 16px;
    }
}

@media screen and (max-width: 820px) {
    .footer {
        padding-bottom: 50px;
    }
    .footer__left {
        width: 100%;
        height: 100%;
    }

    .footer__container {
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .footer__left-wrap {
        justify-content: space-between;
    }

    .footer__switch {
        flex-direction: row-reverse;
        width: 66%;
        display: flex;
        justify-content: space-between;
    }

    .footer__title {
        font-weight: 700;
        font-size: 28px;
    }

    .footer__p {
        font-size: 20px;
    }

    .footer__switch {
        column-gap: 15px;
        align-items: center;
    }

    .footer__link {
        width: 30px;
    }

    .footer__social {
        justify-content: flex-end;
    }

}

@media screen and (max-width: 700px) {
    .footer__left {
        column-gap: 22px;
    }

    .footer__right {
        row-gap: 5px;
    }

    .footer__link {
        width: 25px;
    }

    .footer__p {
        font-size: 15px;
    }

    .footer__info {
        row-gap: 10px;
    }
}


@media screen and (max-width: 600px) {
    #logoFooter{
        width: 100px;
        height: 100px;
    }
    #qrFooter{
        width: 75px;
        height:75px;
    }
    .footer__left-wrap {
        column-gap: 15px
    }

    .footer__right {
        width: 100%;
    }


    .footer__info {
        width: 60%;
        text-align: center;
    }

    .footer__title {
        font-size: 18px;
    }

    .footer__p {
        font-size: 14px;
    }
    .footer__right .footer__title {
        font-size: 24px;
    }

    .footer__social {
        width: 120px;
    }
    .footer{
        padding:50px 0 100px 0;
    }

}

@media screen and (max-width: 532px) {

    .footer__left-wrap {
        height: 130px;
    }

    .footer__right {
        width: 100%;
    }

    .footer__info {
        width: 60%;
        row-gap: 15px;
    }
}

@media screen and (max-width: 480px) {
    .footer__title {
        font-size: 16px;
    }
    .footer__p {
        font-size: 12px;
    }
    .footer__right .footer__title {
        font-size: 20px;
    }
}

@media screen and (max-width: 400px) {

    .footer__title {
        font-size: 16px;
    }
    .footer__p {
        font-size: 12px;
    }
    .footer__right .footer__title {
        font-size: 20px;
    }
    .footer__left-wrap {
        column-gap: 10px;
    }
}

@media screen and (max-width: 375px) {
    .footer {
        padding-top: 30px;
    }
    #lineFooter {
        display: none;
    }
    #qrFooter{
        max-width: 100%;
        width: 40%;
    }
    #logoFooter{
        max-width: 100%;
        width: 30%;
    }



    .footer__left {
        column-gap: 12px;
    }


    .footer__title {
        font-size: 14px;
    }

    .footer__p {
        font-size: 12px;
    }

    .footer__right .footer__title {
        font-size: 18px;
    }
}

@media screen and (max-width: 320px) {

    #logoFooter{
        width: 75px;
        height:75px;
    }
    #qrFooter{
        width: 50px;
        height:50px;
    }
    .footer__title {
        font-size: 13px;
    }

    .footer__p {
        font-size: 11px;
    }

    .footer__right .footer__title {
        font-size: 16px;
    }
}