.confirm__backdrop{
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 11;
}
.modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding:25px 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 600px;
    height: 400px;
    transition: transform 0.4s;
    z-index: 2;
}
.modal img{
    width: 90px;
    height: 90px;
}
.modal h2{
    margin:30px 0 15px;
}
.modal p{
    text-align: center;
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    opacity: 0.7;
}
.modal button{
    margin-top:30px;
    padding:10px 56px;
}

@media screen and (max-width: 768px) {
  .modal h2{
      font-size: 30px;
  }
    .modal p{
      font-size: 18px;
    }
    .modal{
        width: 400px;
        height: 360px;
    }
}

@media screen and (max-width: 576px) {
    .modal{
        width: 80%;
        /*height: 360px;*/
    }
}

@media screen and (max-width: 375px) {
  .modal{
      padding:25px 10px;
      height: 380px;
  }
    .modal h2{
        font-size: 22px;
    }
    .modal p{
        font-size: 15px;
    }
}