.mySwiper {
    padding: 0 7.5% !important;
}

.detoxProgram__box {
    background: #fff;
    box-shadow: 0 2px 10px rgba(87, 87, 87, 0.2);
    border-radius: 5px;
    display: flex;
    flex-direction: column;

}

.detoxProgram__img {
    background: linear-gradient(to right, #a8ff78, #78ffd6);
}

.detoxProgram__box-items {
    padding: 30px 30px 0;
    min-height: 250px;
}

.detoxProgram__box-items p:nth-child(odd) {
    font-weight: 700;
    line-height: 22px;
    color: #5AAA65;
    text-transform: uppercase;
}

.detoxProgram__box-items p:nth-child(2) {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    margin: 10px 0 15px;
}

.detoxProgram__box a {
    text-align: center;
}

.detoxProgram__button {
    padding: 10px 0;
    text-align: center;
    margin: 0 auto 25px;
    width: 80%;
    cursor: pointer;
}

.detox__program-bg {
    background: linear-gradient(180deg, rgba(237, 238, 240, 0) 0%, #EDEEF0 4.52%, #EDEEF0 95.83%, rgba(237, 238, 240, 0) 100%);
}

.detoxProgram__head{
    color: #5AAA65;
}

.mySwiper .swiper-slide {
    margin: 20px 0;
}

@media screen and (min-width: 2000px) {
    .mySwiper {
        padding: 0 100px !important;
        max-width: 1500px;
    }
}

@media screen and (max-width: 640px) {
    #detoxProgram{
        padding:100px 0;
    }

    .mySwiper {
        padding: 0 15% !important;
    }

    .detoxProgram__box-items{
        word-wrap:break-word ;
    }
}