.telegram {
    width: 70%;
}

.telegram__wrap {
    display: flex;
    flex-direction: column;
}

.telegram__wrap-input {
    display: flex;
}

.telegram__count {
    display: flex;
    justify-content: space-between;
    width: 110px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
    font-size: 25px;
    color: #B3B3B3;
    font-family: 'Gilroy-500', serif;
}

.telegram__minus, .telegram__plus {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.telegram__box {
    display: flex;
    flex-direction: column;

}
.telegram__box-2{
    margin-left:20px;
    width: 100%;
}

.telegram__box input {
    text-align: center;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 25px;
    outline: none;
    caret-color : #5AAA65;
    caret-shape: underscore;
}


.telegram__box input::placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.telegram button {
    width: 100%;
    padding: 15px 0;
    margin-top: 10px;
}

.telegram__disabled{
    opacity: 0.5;
}

.telegram label {
    font-family: 'Gilroy-Bold-700', serif;
    font-size: 14px;
    margin-bottom: 10px;
}
.telegram__red{
    color:red;
}
.telegram__errNetwork{
    font-size: 24px!important;
    text-decoration: underline;
    color:red;
}

.telegram__counter {
    color: black
}

@media screen and (max-width: 1200px) {
    .telegram__box input {
        font-size: 18px;
    }
}

@media screen and (max-width: 1024px) {
    .telegram {
        width: 60%;
    }

    .telegram__box input {
        font-size: 16px;
    }
}


@media screen and (max-width: 700px) {
    .telegram {
        width: 60%;
    }
}

@media screen and (max-width: 576px) {
    .telegram, .telegram__box:last-child, .telegram__box input {
        width: 100%;
    }
}

@media screen and (max-width: 320px) {
    .telegram__box input {
        font-size: 13px;
    }

    .telegram label {
        font-size: 12px;
    }
}

@media screen and (max-width: 280px) {
    .telegram__box input {
        font-size: 13px;
    }

    .telegram label {
        font-size: 12px;
    }
}