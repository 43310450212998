.mobile-btn{
    width: 55px;
    height: 30px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    z-index: 10;
}

.mobile-btn span{
    width:100%;
    height: 3px;
    background:#5AAA65 ;
    display: inline-block;
    position: relative;
    border-radius: 20px;
}

.mobile-btn span:before,
.mobile-btn span:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #5AAA65;
    transition: .5s;
    border-radius: 20px;
}
.mobile-btn span:before{
    top:-13px;
}

.mobile-btn span:after{
    top:13px;
}


