#feedback {
    background-image: url("../../assets/images/video-bg.png");
}

#feedback h2 {
    color: #ffffff;
}

.video__box {
    height: 530px;
    width: 100%;
    background: rgba(128, 128, 128, 0.4);
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
}


.video__box video {
    width: 100%;
    cursor: pointer;
}

.video__box img {
    width: 100%;
    object-fit: contain;
    cursor: pointer;
    /*pointer-events: none;*/
}

.feedback__play-btn{
    position: absolute;
    width: 100px;
    height: 100px;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    margin:auto auto;
    z-index: -1;
}

video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;
}

