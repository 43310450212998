.advantage__box{
    text-align: center;
}

.advantage__box svg{
    width: 150px;
    height: 150px;
}

.advantage__box p{
    font-family: 'Gilroy-Bold-700',serif;
    line-height: 22px;
    width: 80%;
    margin:15px auto 27px;
}

@media screen and (max-width: 1024px) {
    #advantage .col-3{
       width: 50%;
    }
}

@media screen and (max-width: 576px) {
    .advantage__box svg,.advantage__box img{
        width: 110px;
        height: 110px;
    }
    .advantage__box p {
        font-size: 14px;
        line-height: 17px;
        width: 100%;
    }
}