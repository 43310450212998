.MuiPaper-root {
    width: 49%;
    border-bottom: 2px solid #EDEEF0;
    box-shadow: unset !important;
    border-radius: unset !important;
    margin: 0 !important;
}

.MuiPaper-root:first-child, .MuiPaper-root:nth-child(2) {
    border-top: 2px solid #EDEEF0;
}

.answer__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.Mui-expanded svg {
    margin: 0;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(45deg) !important;
}

.MuiPaper-root:before {
    content: none !important;
}

.MuiAccordionSummary-expandIconWrapper svg {
    color: #5AAA65;
}

.MuiAccordionSummary-expandIconWrapper svg {
    background: #FBFBFB;
    border-radius: 30px;
    width: 40px;
    height: 40px;
}


.MuiAccordionSummary-root:hover .MuiSvgIcon-root {
    background: #5AAA65;
    transition: ease 0.3s;
    color: white;
}

.MuiAccordionSummary-content {
    margin: 30px 0 !important;
}

.MuiButtonBase-root {
    padding: 0 !important;
}

.MuiTypography-root {
    font-family: 'Gilroy-Bold-700', serif !important;
    font-size: 18px !important;
    line-height: 21px !important;
}

.answer__list li {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
}

@media screen and (max-width: 1024px) {
    .MuiPaper-root {
        width: 100%;
    }

    .MuiPaper-root:nth-child(2) {
        border-top: none;
    }
}

@media screen and (max-width: 576px) {
    .MuiTypography-root {
        font-size: 13px;
    }
}


