#details {
    position: relative;
}

.details__box-2 {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.details__image {
    width: 100%;
}

.details__small {
    width: 100%;
    cursor: pointer;
}


.details__box ul, .details__box-2 ul {
    list-style: none;
    padding-left: 40px;
}

.details__title {
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    color: #5AAA65;
}

.details__price {
    font-weight: 600;
    font-size: 20px;
    color: #5AAA65;
}

.details__description {
    font-family: 'Gilroy-500', serif;
    font-size: 24px;
    font-weight: 500;
    color: #000000;
}

.details__listTitle {
    font-family: 'Gilroy-600', serif;
    font-size: 16px;
    color: #000000;
}

.details__box-2 ul {
    padding-left: 40px;
    margin: 18px 0 25px;
}

.details__box-2 ul li {
    list-style-image: url('../../assets/images/leaf.svg');
    margin-top: 10px;
}

.details__box-2 ul li span {
    position: relative;
    top: -2px;
}

.details__navigate {
    position: absolute;
    right: 28px;
    top: 28px;
    cursor: pointer;
}

.details__answer {
    font-weight: 700;
    color: #5AAA65;
    cursor: pointer;
}

.details__text, .details__box-2 ul li, .details__box ul li {
    font-family: 'Gilroy-500', serif;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
}

.mySwiper3 .swiper-wrapper{
    display: flex;
    align-items: center;
}




@media screen and (max-width: 1024px) {
    #details .col-6 {
        width: 100%;
    }

    #details {
        padding: 100px 0;
    }
}

@media screen and (max-width: 576px) {
    .details__title {
        font-size: 20px;
    }
    .details__description{
        font-size: 21px;
    }
}

@media screen and (max-width: 375px) {
    .details__title, .details__price {
        font-size: 14px;
    }
}

