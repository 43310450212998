header{
    position: fixed;
    width: 100%;
    background: #FFFFFF;
    border-bottom: 2px solid rgba(6, 37, 27, 0.05);
    padding:25px 0;
    font-family: 'Gilroy-600',serif;
    z-index: 10;
}

.header__nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headers__icons{
    display: none;
}

.header__logo-disappear{
    display: none;
}


.header__logo{
    width: 130px;
    height: 35px;
    cursor:pointer;
}

.header__nav > a{
    display: flex;

}

.header__nav ul{
    display: flex;
    column-gap:50px;
    align-items: center;
}
.header__nav ul li{
    cursor:pointer;
}

.header__nav ul li a{
    color: #06251B;
    font-size: 16px;
    position: relative;
}
.header__nav ul li a:before{
    content:'';
    position: absolute;
    bottom:-5px;
    width: 100%;
    height: 2px;
    background: #5AAA65;
    transform: scale(0);
    transition: ease 0.3s;
}
.header__nav ul li a:hover:before {
    transform: scale(1);
}


.header__nav  button{
    padding:10px 17px;
    font-size: 18px;
}

.header__tel{
    display: none;
}
.header__tel p{
    font-family: 'Gilroy-Bold-700',serif;
    color: #5AAA65;
    letter-spacing: 0.02em;
    font-size: 28px;

}


.selected {
    color:hotpink;
    font-weight: bold;
}

@media screen and (max-width: 1400px) {
    .header__nav ul li:nth-child(6){
        margin-left:0;
    }
}

@media screen and (max-width: 1300px) {
    .header__nav ul{
        column-gap:30px;
    }

}

@media screen and (max-width: 1200px) {
    .header__nav ul li a{
        font-size:14px
    }
    .header__nav button {
       font-size: 18px;
    }
}

@media screen and (max-width: 1100px) {
    .header__nav ul{
        column-gap:20px;
    }


}

@media screen and  (max-width: 1024px) {

    /*FORBUREGER*/ /*FORBUREGER*/
    .header__tel{
        display: block;
    }
    .headers__icons{
        display: flex;
        column-gap:30px;
    }
    .header__logo-disappear{
        display: block;
    }
    .mobile-btn {
        display: flex;
        z-index: 1000;
    }
    .show-mobile-btn span{
        background: transparent;   /*hides middle line*/

    }
    .show-mobile-btn span:before,
    .show-mobile-btn span:after{
        top:0;
        bottom: 0;
        margin: auto;

    }
    .show-mobile-btn span:before{
        transform: rotate(45deg);
    }
    .show-mobile-btn span:after{
        transform: rotate(-45deg);
    }
    .nav{
        display: flex;
        flex-direction: column;

        align-items: center;
        position: fixed;
        background: #FFF;  /* fallback for old browsers */
        height: auto;
        width: 100%;
        top:-300%;
        padding:50px 0 150px;
        z-index: 5;
        right: 0;
        transition: .5s;
    }
    .show-nav{
        top: 0;
    }

    /*FORBUREGER*/ /*FORBUREGER*/
    .header__nav ul{
        row-gap:40px;

    }
    .header__nav button{
        padding:15px 90px;
    }


    .header__nav ul li a{
        font-size: 18px;
    }

/*backdrop*/
    .backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        z-index: 4;
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity 0.5s linear;
    }
    .disappear{
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }

    /*backdrop*/

}

@media screen and  (max-height:  750px) {
    .header__nav ul{
        row-gap:35px;
    }

}

@media screen and  (max-height:  680px) {
    .header__nav ul{
        row-gap:30px;
    }
}

@media screen and (max-width: 576px) {
    .nav{
        padding:40px 0 50px;
    }
    .header__nav button {
        padding: 15px 40px;
    }
}

@media screen and (max-width: 375px){
    .mobile-btn {
        width: 40px;
    }
    header {
        padding: 15px 0;
    }
    .nav{
        height: 100%;
    }
}

@media screen and (max-width: 320px){
    .header__nav ul {
        row-gap: 25px;
    }
    .header__nav ul li a {
        font-size: 14px;
    }
    .header__tel p {
        font-size: 16px;
    }
    .header__logo {
        width: 100px;
    }
}

